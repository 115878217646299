/* eslint-disable import/prefer-default-export */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable camelcase */
/* eslint-disable no-var */
import './src/styles/globals.css';

export const onInitialClientRender = () => {
  var Tawk_API = Tawk_API || {}; var
    Tawk_LoadStart = new Date();
  (function () {
    var s1 = document.createElement('script'); var s0 = document.getElementsByTagName('script')[0];
    s1.async = true;
    s1.src = 'https://embed.tawk.to/6267ef177b967b11798c949e/1g1ivrtll';
    s1.charset = 'UTF-8';
    s1.setAttribute('crossorigin', '*');
    s0.parentNode.insertBefore(s1, s0);
  }());
};
